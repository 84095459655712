<template>
  <div class="surfboards m-auto" style="width:90vw; height: 100%">
    <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
        <div class="text-700 text-center">
            <div class="text-900 font-bold text-5xl mb-3">Boards Incoming!</div>
            <div class="text-700 text-2xl mb-5">
                Due to recent swell and pumping breaks, our boards our currently made to order.
                Feel free to browse the gallery or reach out to us if you're looking for a stick!
            </div>
            <router-link class="no-underline" to="/contact">
                <Button label="Contact Us" class="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"></Button>
            </router-link>
        </div>
    </div>
    <div data-behold-id="VJqVhAD6nsNUtoxAOMJj"></div>
  </div>
  
</template>

<script>
export default {

}
</script>

<style scoped>
.surfboards{
  font-family: 'Open Sans', Tahoma, Geneva, Verdana, sans-serif;
}
</style>