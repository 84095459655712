<template>
    <div class="story-container bg-dark max-w-screen overflow-hidden">
      <div class="story-header surface-section px-4 py-8 md:px-6 lg:px-8 h-50rem">
          <div class="text-700 text-center pt-5" style="">
              <div class="text-primary font-bold sm:text-5xl md: text-5xl lg:text-8xl mb-3" style="background: rgba(33, 37, 41, 0.4);">404 Page Not Found :(</div>
              <div class="text-primary font-bold sm:text-xl lg:text-4xl mb-3" style="background: rgba(33, 37, 41, 0.4);">
                  Get back home by clicking <router-link to="/">here</router-link>
              </div>
          </div>
      </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>>